import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const InvoicesNonReturnableListform = (props) => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    handleFetchInvoices();
  }, []);

  const handleFetchInvoices = async () => {
    try {
      const response = await fetch(
        `https://www.mapatrawala.com/inventory/api/NRetinvoice/invoice/${props.newsaleorderId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log("Fetched Data:", data); // Log the response to see its structure

      // Assuming data is an array of invoices
      if (Array.isArray(data)) {
        setInvoices(data);
      
      } else {
        console.error("Expected an array of invoices, but received:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box m="20px">
      {invoices.length > 0 ? (
        invoices.map((invoice, index) => (
          <Box key={index} mb="20px">
            

            {invoice.invoicepdf ? (
              <iframe
                src={`data:application/pdf;base64,${invoice.invoicepdf}`}
                style={{ width: "100%", height: "400px", border: "none" }}
                title={`Invoice PDF ${invoice.invoiceNo}`}
              ></iframe>
            ) : (
              <p>No PDF data found for this invoice.</p>
            )}
          </Box>
        ))
      ) : (
        <p>Loading invoices...</p>
      )}
    </Box>
  );
};

export default InvoicesNonReturnableListform;
