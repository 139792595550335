/** @format */

import React, { useState } from "react";
import { Button, TextField, Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  card: {
    padding: theme.spacing(4),
    borderRadius: "10px",
    backgroundColor: "#F1F4F8",
    width: "40%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#5cb85c",
    color: "#FFFFFF",
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    setLoading(true);

    // Create a FormData object
    const formData = new FormData();
    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);

    try {
      const response = await fetch("https://www.mapatrawala.com/inventory/api/registration/changepassword", {
        method: "PUT",
        body: formData, // Send FormData directly
      });

      setLoading(false);

      if (response.ok) {
        swal("Success!", "Password changed successfully!", "success");
        navigate("/dashboard"); // Navigate to the dashboard
      } else {
        const errorData = await response.json();
        swal("Error!", errorData.message || "Failed to change password.", "error");
      }
    } catch (err) {
      setLoading(false);
      swal("Server issue!", "Check server connection", "error");
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
          Change Password
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            fullWidth
            label="Old Password"
            type="password" // Use 'password' type for security
            name="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            margin="normal"
          />
          <TextField
            variant="outlined"
            fullWidth
            label="New Password"
            type="password" // Use 'password' type for security
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={handleChangePassword}
            disabled={loading} // Disable button while loading
          >
            {loading ? "Changing..." : "Change Password"}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ChangePassword;
