/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import Header from "../../components/Header";


const AdminPurchaseRequestList = (props) => {
  const [tableDataForm, setTableData] = useState([]);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchingData();
  }, []);

  const fetchingData = async () => {
    try {
      const response = await fetch(
        "https://www.mapatrawala.com/inventory/api/Porequest/allitems"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log(data);
      setTableData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleApproved = (index) => {
    // Handle approval logic here, you can use the index or row data to identify the item
    console.log("Approved item at index:", index);
  };

  // const exportToExcel = () => {
  //   // Filter the data to only include the necessary fields
  //   const filteredData = tableDataForm.map((item, index) => ({
  //     'S.No': index + 1,               // Generate S.No manually
  //     'CompanyName': item.companyName,  // Assuming 'CompanyName' is the correct field in your data
  //     'EmailId': item.emailId,          // Assuming 'emailId' is the correct field in your data
  //     'Pono': item.poNo,                // Assuming 'Pono' is the correct field in your data
  //     'Amount': item.amount             // Assuming 'amount' is the correct field in your data
  //   }));
  
  //   // Create a new workbook and add the filtered data to the worksheet
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.json_to_sheet(filteredData);
  
  //   // Get the range of the worksheet
  //   const range = XLSX.utils.decode_range(worksheet['!ref']);
  
  //   // Loop through the first row (header row) to style it
  //   for (let C = range.s.c; C <= range.e.c; ++C) {
  //     const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
  //     if (!worksheet[cellAddress]) continue;
      
  //     // Apply styling to the header
  //     worksheet[cellAddress].s = {
  //       fill: {
  //         fgColor: { rgb: "FF0000" }, // Red background color for header
  //       },
  //       font: {
  //         bold: true,                 // Bold font
  //         color: { rgb: "FFFFFF" },   // White text color for better contrast
  //       }
  //     };
  //   }
  
  //   // Append worksheet to the workbook
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
  //   // Convert the workbook to Excel format and trigger the download
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //     cellStyles: true // Enable cell styles
  //   });
  
  //   // Use the saveAs function to download the Excel file
  //   saveAs(
  //     new Blob([excelBuffer], { type: "application/octet-stream" }),
  //     "purchase_requests.xlsx"
  //   );
  // };
  const exportToExcel = () => {
    // Filter the data to only include the necessary fields
    const filteredData = tableDataForm.map((item, index) => ({
      'S.No': index + 1,               // Generate S.No manually
      'CompanyName': item.companyName,  // Assuming 'CompanyName' is the correct field in your data
      'EmailId': item.emailId,          // Assuming 'emailId' is the correct field in your data
      'Pono': item.poNo,                // Assuming 'Pono' is the correct field in your data
      'Amount': item.amount             // Assuming 'amount' is the correct field in your data
    }));
  
    // Create a new workbook and add the filtered data to the worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
  
    // Get the range of the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref']);
  
    // Loop through the first row (header row) to style it
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;
      
      // Apply styling to the header with xlsx-style
      worksheet[cellAddress].s = {
        fill: {
          fgColor: { rgb: "FF0000" }, // Red background color for header
        },
        font: {
          bold: true,                 // Bold font
          color: { rgb: "FFFFFF" },   // White text color for better contrast
        }
      };
    }
  
    // Append worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
    // Convert the workbook to Excel format and trigger the download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
      cellStyles: true // Enable cell styles
    });
  
    // Use the saveAs function to download the Excel file
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "purchase_requests.xlsx"
    );
  };

  return (
    <div style={{ margin: "20px" }}>
      <Box mt="20px">
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title="PURCHASE REQUEST LIST" />
          <Box>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              variant="contained"
              color="primary"
              onClick={exportToExcel}
            >
              <DownloadOutlinedIcon sx={{ mr: "10px" }} />
              Export
            </Button>
          </Box>
        </Box>

        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "#525DB5" }}>
            <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>
                S.No
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Company Name
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Email Id
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                PO Number
              </th>
             
              <th style={{ border: "1px solid black", padding: "8px" }}>
              Amount
              </th>
              
            </tr>
          </thead>
          <tbody>
            {tableDataForm.map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {row.companyName}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {row.emailId}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {row.poNo}
                </td>
                
                {/* <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {row.purchaseListReq.rate}
                </td> */}
                <td
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {row.amount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </Box>
    </div>
  );
};

export default AdminPurchaseRequestList;
