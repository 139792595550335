/** @format */
import React, { useState } from "react";
import { Button, TextField, Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundImage: "url('https://i.pinimg.com/originals/38/a6/8f/38a68ffdb3f8585487c78dbc41831ed1.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    alignItems: "center",
  },
  card: {
    padding: theme.spacing(4),
    borderRadius: "10px",
    backgroundColor: "#F1F4F8",
    width: "30%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#5cb85c",
    color: "#FFFFFF",
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async () => {
    // Validation logic
    const validationErrors = {};
    if (!companyName) validationErrors.companyName = "Company Name is required";
    if (!email || !/\S+@\S+\.\S+/.test(email)) validationErrors.email = "Invalid email format";
    if (!address) validationErrors.address = "Address is required";
    if (!pincode || !/^\d{6}$/.test(pincode)) validationErrors.pincode = "Pincode must be 6 digits";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    // Create form data
    const formData = new URLSearchParams();
    formData.append("companyName", companyName);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("pincode", pincode);

    try {
      const response = await fetch("https://www.mapatrawala.com/inventory/api/registration/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });

      if (response.ok) {
        swal("Success!", "Account created successfully!", "success");
        navigate("/"); // Navigate to the dashboard or another route
      } else {
        const errorData = await response.json();
        swal("Error!", errorData.message || "Failed to create account.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("Error!", "An error occurred while creating your account.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
          Sign Up
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            margin="normal"
            error={!!errors.companyName}
            helperText={errors.companyName}
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            margin="normal"
            error={!!errors.address}
            helperText={errors.address}
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Pincode"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            margin="normal"
            error={!!errors.pincode}
            helperText={errors.pincode}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={handleSignUp}
            disabled={loading}
          >
            {loading ? "Creating Account..." : "Create Account"}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default SignUp;
