import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const InvoicesReturnableListform = (props) => {
  const [invoices, setInvoices] = useState([]);
  const [pdfUrls, setPdfUrls] = useState({}); // Store Blob URLs for each invoice

  useEffect(() => {
    handleFetchInvoices();
  }, []);

  const handleFetchInvoices = async () => {
    try {
      const response = await fetch(
        `https://www.mapatrawala.com/inventory/api/Rinvoice/rinvoice/${props.newsaleorderId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log("Fetched Data:", data); // Log the response to see its structure

      if (Array.isArray(data)) {
        setInvoices(data);

        const newPdfUrls = {};
        data.forEach((invoice, index) => {
          if (invoice.invoicepdf) {
            try {
              const base64String = invoice.invoicepdf.startsWith(
                "data:application/pdf;base64,"
              )
                ? invoice.invoicepdf.split(",")[1]
                : invoice.invoicepdf;

              const byteCharacters = atob(base64String);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: "application/pdf" });
              newPdfUrls[index] = URL.createObjectURL(blob);

              console.log(`Blob URL for invoice ${index}:`, newPdfUrls[index]);
            } catch (error) {
              console.error("Error converting Base64 to Blob:", error);
            }
          }
        });
        setPdfUrls(newPdfUrls);
      } else {
        console.error("Expected an array of invoices, but received:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box m="20px" style={{ height: '100vh' }}> {/* Full viewport height */}
      {invoices.length > 0 ? (
        invoices.map((invoice, index) => (
          <Box key={index} mb="20px" style={{ height: '800px', overflow: 'hidden' }}> {/* Overflow hidden to prevent clipping */}
            {invoice.invoicepdf ? (
              <>
                {pdfUrls[index] ? (
                 <Box style={{ width: "100%", height: "1200px", border: "none", overflow: "hidden" }}>
                 <iframe
                   src={pdfUrls[index]}
                   style={{ width: "100%", height: "100%", border: "none" }}
                   title={`Invoice PDF ${index}`}
                   allowFullScreen
                 />
               </Box>
               
                ) : (
                  <p>Loading PDF for invoice {index}...</p>
                )}
              </>
            ) : (
              <p>No PDF data found for this invoice.</p>
            )}
          </Box>
        ))
      ) : (
        <p>Loading invoices...</p>
      )}
    </Box>
  );
};

export default InvoicesReturnableListform;

// import React, { useEffect, useState } from "react";
// import { Box } from "@mui/material";

// const InvoicesReturnableListform = (props) => {
//   const [invoices, setInvoices] = useState([]);
//   const [pdfUrls, setPdfUrls] = useState({}); // Store Blob URLs for each invoice

//   useEffect(() => {
//     handleFetchInvoices();
//   }, []);

//   const handleFetchInvoices = async () => {
//     try {
//       const response = await fetch(
//         `https://www.mapatrawala.com/inventory/api/Rinvoice/pdfs/${props.newsaleorderId}`
//       );
//       if (!response.ok) {
//         throw new Error("Failed to fetch data");
//       }
//       const data = await response.json();
//       console.log("Fetched Data:", data); // Log the response to see its structure

//       if (Array.isArray(data)) {
//         setInvoices(data);
//         // Generate Blob URLs for each invoice
//         const newPdfUrls = {};
//         data.forEach((invoice, index) => {
//           if (invoice.invoicepdf) {
//             const base64String = invoice.invoicepdf.startsWith("JVBER")
//               ? invoice.invoicepdf // If it's already a base64 string
//               : `data:application/pdf;base64,${invoice.invoicepdf}`;

//             // Convert base64 to Blob
//             const byteCharacters = atob(base64String);
//             const byteNumbers = new Array(byteCharacters.length);
//             for (let i = 0; i < byteCharacters.length; i++) {
//               byteNumbers[i] = byteCharacters.charCodeAt(i);
//             }
//             const byteArray = new Uint8Array(byteNumbers);
//             const blob = new Blob([byteArray], { type: "application/pdf" });
//             newPdfUrls[index] = URL.createObjectURL(blob); // Create URL for the Blob
//           }
//         });
//         setPdfUrls(newPdfUrls); // Update the state with Blob URLs
//       } else {
//         console.error("Expected an array of invoices, but received:", data);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <Box m="20px">
//       {invoices.length > 0 ? (
//         invoices.map((invoice, index) => (
//           <Box key={index} mb="20px">
//             {invoice.invoicepdf ? (
//              <iframe
//              src={pdfUrls[index]} // Use Blob URL
//              style={{ width: "100%", height: "100vh", border: "none" }} // Use viewport height
//              title={`Invoice PDF ${index}`}
//            ></iframe>
           
//             ) : (
//               <p>No PDF data found for this invoice.</p>
//             )}
//           </Box>
//         ))
//       ) : (
//         <p>Loading invoices...</p>
//       )}
//     </Box>
//   );
// };

// export default InvoicesReturnableListform;
