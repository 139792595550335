/** @format */
import React, { useState } from "react";
import { Button, TextField, Typography, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundImage: "url('https://i.pinimg.com/originals/38/a6/8f/38a68ffdb3f8585487c78dbc41831ed1.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh", // Cover full height of the viewport
    alignItems: "center", // Center card vertically
  },
  card: {
    padding: theme.spacing(4),
    borderRadius: "10px",
    backgroundColor: "#F1F4F8",
    width: "400px",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#5cb85c",
    color: "#FFFFFF",
    transition: "transform 0.2s ease-in-out", // For the swell effect
    "&:hover": {
      backgroundColor: "#4cae4c", // Slightly darker green on hover
      transform: "scale(1.05)", // Swell effect on hover
    },
    "&:active": {
      transform: "scale(0.95)", // Shrink slightly on click
    },
  },
  bottomText: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  signUpLink: {
    fontWeight: "bold",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // For loading state
  const navigate = useNavigate();

  // Validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validate password length and complexity (min length 6)
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleLogin = async () => {
    setError(""); // Reset error message

    if (!validateEmail(email)) {
        setError("Invalid email format");
        return;
    }

    setLoading(true);
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", password);

    try {
        const response = await fetch("https://www.mapatrawala.com/inventory/api/registration/verify", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData,
        });

        setLoading(false); // Set loading to false after response is received

        // Check if response is okay (status code 200)
        if (response.ok) {
            const responseText = await response.text(); // Read response as plain text

            // Check if the response matches the success message
            if (responseText.trim() === "login successfully") {
                // Set admin role in localStorage
                localStorage.setItem("role", "admin");
                swal("Good job!", "Login Successful!", "success");
                navigate("/dashboard"); // Navigate to the dashboard
            } else {
                setError("Login failed. Please try again.");
                swal("Bad job!", "Login Failed!", "error");
            }
        } else {
            setError("Login failed. Please try again.");
            swal("Bad job!", "Login Failed!", "error");
        }
    } catch (err) {
        setLoading(false);
        setError("An error occurred. Please try again.");
        swal("Server issue!", "Check server connection", "error");
    }
};




  const handleSignUp = () => {
    navigate("/signup"); // Navigate to sign-up page
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
          Sign In
        </Typography>
        <div className={classes.form}>
          <TextField
            variant="outlined"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            error={!!error && !validateEmail(email)} // Highlight if invalid
            helperText={error && !validateEmail(email) ? "Invalid email" : ""}
          />
          <TextField
            variant="outlined"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            error={!!error && !validatePassword(password)} // Highlight if invalid
            // helperText={error && !validatePassword(password) ? "Password must be at least 6 characters" : ""}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={handleLogin}
            disabled={loading} // Disable button when loading
          >
            {loading ? "Signing In..." : "Sign In"}
          </Button>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <div className={classes.bottomText}>
            <Typography variant="body2">
              Not registered yet?{" "}
              <span className={classes.signUpLink} onClick={handleSignUp}>
                Create new account
              </span>
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SignIn;
